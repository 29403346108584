<template ref="adminUser">
  <div>
      <header-admin-pages
        title="Противодействие коррупции"
        :counter="resArray.length"
        :button="{ title: 'Добавить информацию', link: '/admin/corruption/create?type=create' }"
      />
      <div class="filters">
        <div style="width: 100%" class="content-sb">
          <p>Секция<span>*</span></p>
          <select required @change="selectGroup" class="input blue" name="SectionId">
            <option disabled selected>Выберите секцию</option>
            <option v-for="item in $store.getters.getCorruptionGroup"
                    :key="`getCorruptionGroup${item.id}`" :value="item.id">
              {{ item.title }}
            </option>
          </select>
        </div>
      </div>
      <main>
        <table>
          <tr>
            <td><p>Название документа</p></td>
            <td><p>Тип ссылки</p></td>
            <td class="drop-icon"></td>
          </tr>
          <tr v-for="item in resArray" :key="`getСorruption${item.id}`">
            <td><p>{{ item.title }}</p></td>
            <td><p>{{ item.is_file ? 'документ': 'ссылка' }}</p></td>
            <td class="drop-icon">
              <drop-list @clickItem="clickItem" :idItem="item.id"/>
            </td>
          </tr>
        </table>
        <pagination/>
      </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import Pagination from '@/components/Pagination'
import DropList from '@/components/Admin/DropList'
export default {
  name: 'Documents',
  data () {
    return {
      resArray: []
    }
  },
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить пользователя ?')
        if (consent) {
          this.$store.dispatch('deleteСorruption', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailСorruption', value.id)
      }
    },
    async selectGroup (e) {
      let res = await fetch(`${this.$store.getters.getUrlApi}api/corruption/section/group/${e.target.value}`)
      res = await res.json()
      this.resArray = await res.data.files
    }
  },
  mounted () {
    this.$store.dispatch('setCorruptionGroup')
  },
  components: {
    HeaderAdminPages,
    Pagination,
    DropList
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}
.drop-icon {
  width: 3rem;
}
</style>
